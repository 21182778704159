<template>
  <div class="container my-5">
    <div class="logoBoxImg">
    <img src="https://firebasestorage.googleapis.com/v0/b/creation-digital/o/images%2Fcropped-Logo-mascareigne-1789-300x300.webp?alt=media&token=0719a6e8-3091-4c6e-83b8-a9c781bf3d6b" alt="logo" class="img-fluid mx-auto d-block">
    </div>
    <h1 class="text-center my-5">Mentions légales & politique de confidentialité</h1>
        <h2 class="text-center">Conditions Générales de Service</h2>

<p>Bienvenue sur le site de Mascareignes-1789 ! </p>

<p> Ces conditions (ainsi que les documents mentionnés ici) constituent les termes et conditions selon
     lesquels l'Association Mascareignes-1789  vous fournit les cours et supports (Cours) énumérés sur notre 
     site web https://Mascareignes1789.com/ (notre site) et représentés par nos supports promotionnels les plus récents.</p>
     <p>Veuillez lire attentivement ces conditions générales avant de commander un Cours sur notre site. 
        Vous devez comprendre qu'en commandant l'un de nos Cours, vous acceptez d'être lié par ces termes et conditions.</p>
    
    <p>En accédant au site https://Mascareignes1789.com/, nous considérons que vous acceptez ces conditions générales. 
        N'utilisez pas Mascareignes-1789 si vous n'acceptez pas toutes les conditions générales énoncées sur cette page.</p>
    <p>La terminologie suivante s'applique à ces Conditions Générales, à la Politique de Confidentialité
         et à la Mention Légale ainsi qu'à tous les Accords :</p>
        <p>Fait référence à vous</p>
        <p>La personne qui se connecte à ce site et conforme aux termes et conditions de l'association-Mascareignes-1789.</p>
        <ul>
            <li>Client</li>
            <li>Vous</li>
            <li>Votre</li>
        </ul>
        <p>Fait référence à la Mascareignes-1789 et/ou ses concédants.</p>
        <ul>
            <li>Association</li>
            <li>Nous</li>
            <li>Notre</li>
        </ul>
        <p>Fait référence à la fois au Client et à nous-mêmes.</p>
        <ul>
            <li>Partie</li>
            <li>Parties</li>
            <li>Nous</li>
        </ul> 
         

   <p>Tous les termes se réfèrent à l'offre, l'acceptation et la considération du paiement nécessaire 
    pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée
     dans le but exprès de répondre aux besoins du Client concernant la fourniture des services indiqués par l'association Mascareignes-1789, 
     conformément aux lois en vigueur.</p>
     <p>Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, 
        avec des majuscules et/ou il/elle ou ils, sont considérés comme interchangeables et donc comme se 
        référant à la même chose.</p>

<h4>**Cookies**</h4>

<p>Nous utilisons des cookies. En accédant à Mascareignes-1789, vous acceptez l'utilisation des cookies 
    conformément à la Politique de Confidentialité de Mascareignes-1789. 
    La plupart des sites web interactifs utilisent des cookies pour récupérer les informations des utilisateurs à chaque visite. 
    Les cookies sont utilisés sur notre site pour permettre le fonctionnement de certaines zones et pour faciliter la navigation des visiteurs. 
    Certains de nos partenaires affiliés/publicitaires peuvent également utiliser des cookies.</p>

<h4>**Licence**</h4>

<p>Sauf indication contraire, Mascareignes-1789 et/ou ses concédants détiennent les droits de propriété intellectuelle pour tout le contenu sur le site Mascareignes-1789. 
   Tous les droits de propriété intellectuelle sont réservés.</p> 
  <p>Vous pouvez accéder à ce contenu à des fins personnelles, sous réserve des restrictions définies dans ces termes 
    et conditions.</p>

<p>Il est interdit de :</p>
<ul>
    <li>-Republier du contenu de Mascareignes-1789</li>
    <li>-Vendre, louer ou sous-licencier du contenu de Mascareignes-1789</li>
    <li>-Reproduire, dupliquer ou copier du contenu de Mascareignes-1789</li>
    <li>-Redistribuer du contenu de Mascareignes-1789</li>
</ul>

<p>Cet Accord prend effet à la date des présentes.</p>
<p>Certaines parties de ce site offrent aux utilisateurs la possibilité de publier et d'échanger des opinions et des 
    informations dans certaines sections du site.
    Mascareignes-1789 ne filtre pas, ne modifie pas, ne publie pas et ne révise pas les Commentaires avant leur publication
    sur le site. </p>
<p>Les Commentaires ne reflètent pas les opinions de Mascareignes-1789, de ses agents et/ou affiliés.
   Ils reflètent les opinions de la personne qui les publie.</p>
   <p> Dans la mesure permise par les lois applicables, Mascareignes-1789 ne saurait être tenue responsable des Commentaires ni de toute responsabilité, 
    dommages ou dépenses causés et/ou subis à la suite de l'utilisation, de la publication ou de l'apparition des Commentaires sur ce site.</p>
    <p>Mascareignes-1789 se réserve le droit de surveiller tous les Commentaires et de supprimer tout Commentaire considéré comme inapproprié, offensant ou en violation de ces Conditions Générales.</p>

<h4>Vous garantissez et déclarez que :</h4>
<ul>
    <li>- Vous avez le droit de publier les Commentaires sur notre site et vous disposez de toutes les licences et autorisations nécessaires pour le faire</li>
    <li>- Les Commentaires ne violent aucun droit de propriété intellectuelle, y compris, sans s'y limiter, les droits d'auteur, brevet ou marque d'un tiers</li>
    <li>- Les Commentaires ne contiennent aucun contenu diffamatoire, offensant, indécent ou autrement illégal qui constitue une atteinte à la vie privée</li>
    <li>- Les Commentaires ne seront pas utilisés pour solliciter ou promouvoir des affaires, des activités commerciales ou illégales.</li>
</ul>
 <p>Vous accordez par la présente à l'association Mascareignes-1789 une licence non exclusive d'utilisation,
    de reproduction, de modification et d'autorisation d'utilisation, de reproduction et de modification de vos
    Commentaires sous toutes formes, formats ou supports.</p>

<h4>**Liens vers notre contenu**</h4>

<p>Les organisations suivantes peuvent créer des liens vers notre site sans autorisation écrite préalable </p>:
<ul>
    <li>- Agences gouvernementales</li>
    <li>- Moteurs de recherche</li>
    <li>- Organismes de presse</li>
    <li>- Distributeurs d'annuaires en ligne</li>
</ul>





<p>Ces organisations peuvent créer un lien vers notre page d'accueil, vers des publications ou vers d'autres informations du site</p>
     tant que le lien : 
     <ul>
        <li>(a) n'est pas trompeur</li>
        <li>(b) ne sous-entend pas faussement un parrainage un soutien ou une approbation de la part de la partie à l'origine du lien</li>
        <li>(c) s'inscrit dans le contexte du site du lien</li>
     </ul> 

<p>Nous pouvons approuver d'autres demandes de liens provenant des organisations suivantes :</p> 
    <ul>
        <li>Sources d'informations commerciales ou grand public</li>
        <li>Sites communautaires en ligne</li>
        <li>Associations caritatives</li>
        <li>Répertoires en ligne</li>
        <li>Portails Internet</li>
        <li>Cabinets d'avocats</li>
        <li>Cabinets de comptabilité et de conseil</li>
        <li>Institutions éducatives</li>
        <li>Associations commerciales</li>
    </ul>
 <p>Si vous faites partie de ces organisations et souhaitez créer un lien vers notre site, 
    vous devez nous en informer en envoyant un e-mail à marioachil@gmail.com.
    Veuillez inclure votre nom, le nom de votre organisation, vos coordonnées ainsi que l'URL de votre site,
     une liste des URLs à partir desquelles vous souhaitez créer un lien vers notre site, 
     et une liste des URLs sur notre site vers lesquelles vous souhaitez créer un lien. 
     Attendez 2 à 3 semaines pour une réponse.</p>

<p>Les organisations approuvées peuvent créer des liens vers notre site comme suit :</p>
<ul>
    <li>- En utilisant notre nom de société ;</li>
    <li>- En utilisant l'URL uniforme du lien</li>
    <li>- En utilisant toute autre description de notre site qui a du sens dans le contexte du site de la partie à l'origine du lien.</li>
</ul>
<p>Aucune utilisation du logo ou des autres illustrations de Mauricode-Academy ne sera autorisée pour la création de liens sans un accord de licence de marque.</p>

<h4>**Responsabilité du contenu**</h4>

<p>Nous ne serons pas tenus responsables du contenu figurant sur votre site. Vous acceptez de nous indemniser contre toutes réclamations résultant de votre site. Aucun lien ne doit apparaître sur un site qui pourrait être interprété comme diffamatoire, obscène ou criminel, ou qui viole de quelque manière que ce soit les droits de tiers.</p>

<h4>**Réservation de droits**</h4>

<p>Nous nous réservons le droit de demander la suppression de tous les liens ou de tout lien particulier vers notre site.
     Vous acceptez de supprimer immédiatement tous les liens vers notre site sur demande. Nous nous réservons également 
     le droit de modifier ces conditions générales et la politique de liens à tout moment. 
     En continuant à lier votre site au nôtre, vous acceptez d'être lié par ces termes et conditions de liens.</p>

<h4>**Suppression des liens de notre site**</h4>

<p>Si vous trouvez un lien sur notre site offensant pour une raison quelconque, vous êtes libre de nous contacter à tout 
    moment. Nous prendrons en compte les demandes de suppression de liens mais nous ne sommes pas obligés de les supprimer
    ou de vous répondre directement.</p>

<p>Nous ne garantissons pas que les informations sur ce site sont correctes, complètes ou précises ; ni que le site restera disponible ou que les informations seront mises à jour.</p>

<h4>**Clause de non-responsabilité**</h4>

<p>Dans toute la mesure permise par la loi applicable, nous excluons toutes déclarations, garanties et conditions relatives à notre site web et à son utilisation. Rien dans cette clause de non-responsabilité ne limitera ou n'exclura notre responsabilité ou celle de nos utilisateurs pour :</p>
<ul>
    <li>- La mort ou les blessures personnelles</li>
    <li>- La fraude ou la fausse déclarations</li>
    <li>Toute responsabilité qui ne peut être exclue par la loi applicable</li>
</ul> 
<p>Les limitations et exclusions de responsabilité énoncées dans cette section sont soumises au
   paragraphe précédent et régissent toutes les responsabilités découlant de la clause de non-responsabilité,
   y compris les responsabilités découlant du contrat, du délit et des obligations légales.</p>

<p>Tant que le site et les informations sur le site sont fournis gratuitement, nous ne serons pas responsables de toute perte ou dommage de quelque nature que ce soit.</p>

 <h4>Droit applicable et attribution de juridiction.</h4>
 <p>Tout litige en relation avec l’utilisation du Réseau des sites Internet de L’association Mascareignes-1789 est soumis
     au droit français. </p>
<p>En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux
     compétents de Saint-Denis.</p>
</div>
</template>

<script>

export default {
  name: 'Ml&PcPage',
  components: {
    
  },
};
</script>

<style scoped>
.logoBoxImg{
    margin-top: 110px;
    margin-bottom: 20px;
    width:100%;
    height: auto;
    text-align:center;

}

p, ul li {
    font-size:20px;
}
</style>